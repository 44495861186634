// External Imports
import React, { Fragment, useEffect, useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
// Config
import { colors } from "../../../exports/assets.exports";
import { urlRoutes } from "../../../exports/constants.exports";
import { ScrollSpy } from "../../../exports/functions.exports";
// Components
import {
  DocsCallToAction,
  Breadcrumb,
  useGuideSidebar,
  SEO,
  ButtonRow,
  ImageElement,
  NoteElement,
  TipElement,
  HeaderText,
  BodyText,
  DividerElement,
  ListElement,
  ListOfContentsComponent,
  GetSimilarGuides,
} from "../../../exports/components.exports";
import { Person } from "@mui/icons-material";
import {
  Box,
  Container,
  Grid,
  Typography,
  Avatar,
  Divider,
} from "@mui/material";

/**
 * @constant query for strapi
 */
export const query = graphql`
  query GuideQuery($slug: String!) {
    strapiGuide(Slug: { eq: $slug }) {
      Author {
        name
        picture {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      Category {
        name
        slug
      }
      Content
      Description
      FeatureImage {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
        }
      }
      SimilarGuides {
        Slug
        Title
      }
      Slug
      Title
      updated_at(fromNow: true)
    }
    allStrapiCategory {
      edges {
        node {
          slug
          name
          articles {
            id
          }
        }
      }
    }
    allGuides: allStrapiGuide {
      edges {
        node {
          Title
          Slug
          Category {
            name
          }
        }
      }
    }
  }
`;

/**
 * @function Guide component
 * @param data
 * @returns the guide component that renders the content from strapi
 */
const Guide = ({ data }: { data: any }) => {
  const guide = data.strapiGuide;
  const {
    Title,
    Author,
    Category,
    Content,
    Description,
    FeatureImage,
    SimilarGuides,
    updated_at,
  } = guide;
  const DrawerSideBar = useGuideSidebar(data);
  const avatarImage = getImage(Author?.picture?.localFile);
  const [listOfContents, setListOfContents] = useState<Array<string>>([]);

  const seo = {
    metaTitle: Title,
    metaDescription: Description,
    shareImage: FeatureImage[0],
    article: true,
  };

  /**
   * @function getDynamicComponent switch statement to map over all data and render the equivalent components for the data
   * @param allDynamicContent all the data for the guide from strapi
   * @returns each individual component that is setup in this project and on the strapi backend
   */
  const getDynamicComponent = (allDynamicContent: any) => {
    return allDynamicContent.map((content: any, index: number) => {
      switch (content.strapi_component) {
        case "guide-components.body-text":
          return (
            <BodyText
              key={`${content.strapi_component}-${index}`}
              bodyText={content}
            />
          );
        case "guide-components.line-break":
          return (
            <DividerElement key={`${content.strapi_component}-${index}`} />
          );
        case "guide-components.text-variant":
          return (
            <HeaderText
              key={`${content.strapi_component}-${index}`}
              headerText={content}
            />
          );
        case "guide-components.numbered-list":
          return (
            <ListElement
              key={`${content.strapi_component}-${index}`}
              listData={content.ListElement}
              bulletPoint={false}
            />
          );
        case "shared.image-component":
          return (
            <ImageElement
              key={`${content.strapi_component}-${index}`}
              imageData={content}
            />
          );
        case "guide-components.note":
          return (
            <NoteElement
              key={`${content.strapi_component}-${index}`}
              nodeText={content}
            />
          );
        case "guide-components.tip":
          return (
            <TipElement
              key={`${content.strapi_component}-${index}`}
              tipText={content}
            />
          );
        case "guide-components.bulleted-list":
          return (
            <ListElement
              key={`${content.strapi_component}-${index}`}
              listData={content.ListElement}
              bulletPoint={true}
            />
          );
        case "guide-components.button-row":
          return (
            <ButtonRow
              buttonData={content.Button}
              key={`${content.strapi_component}-${index}`}
            />
          );
        default:
          return null;
      }
    });
  };

  /**
   * @function useEffect
   * sets the headers for the list of contents, this is only shown on desktop/laptop screens
   */
  useEffect(() => {
    let tmpHeaderList: Array<string> = [];
    Content.map((dynamicItem: any) => {
      if (dynamicItem.strapi_component === "guide-components.text-variant") {
        tmpHeaderList.push(dynamicItem.Text);
      }
    });
    setListOfContents(tmpHeaderList);
  }, []);

  return (
    <Fragment>
      <SEO seo={seo} />
      <DocsCallToAction />
      <ScrollSpy>
        <Box
          sx={{ marginBottom: "5rem", position: "relative" }}
          className="dynamic-guide"
        >
          <Container maxWidth="md">
            <Box
              sx={{
                display: "inline-flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginY: "2rem",
              }}
            >
              <Breadcrumb
                primary={{ text: "Guides", href: urlRoutes.GUIDES }}
                secondary={{
                  text: `${Category.name}`,
                  href: `${urlRoutes.GUIDES}/${Category.slug}`,
                }}
                currentPage={Title}
              />
              <Box>{DrawerSideBar}</Box>
            </Box>
          </Container>
          <Divider sx={{ marginBottom: "5rem" }} />

          {listOfContents.length > 0 && (
            <ListOfContentsComponent contentList={listOfContents} />
          )}
          <Container
            maxWidth="md"
            className="dynamic-guide__container"
            sx={{
              paddingRight: {
                xl: "initial !important",
                lg: "10rem !important",
              },
            }}
          >
            <Grid container className="dynamic-guide__grid" spacing={4}>
              <Grid item xs={12} className="dynamic-guide__grid-item">
                <Typography
                  variant="h2"
                  className="dynamic-guide__title"
                  color={colors.swiftcaseDarkBlue}
                  sx={{ marginBottom: "2rem" }}
                >
                  {Title}
                </Typography>
                <Typography
                  variant="h5"
                  className="dynamic-guide__title"
                  color={colors.swiftcaseMidGrey}
                >
                  Last Updated: {updated_at}
                </Typography>
              </Grid>

              {Content && getDynamicComponent(Content)}

              <Grid
                item
                xs={12}
                className="dynamic-guide__grid-item"
                sx={{ display: "inline-flex", alignItems: "center" }}
              >
                {avatarImage ? (
                  <GatsbyImage
                    loading="lazy"
                    style={{
                      width: "4rem",
                      height: "4rem",
                      borderRadius: "50%",
                      marginRight: "1rem",
                    }}
                    image={avatarImage}
                    alt={Author?.name ?? "The Swiftcase Team"}
                  />
                ) : (
                  <Avatar
                    sx={{
                      marginRight: "1rem",
                      height: "4rem",
                      width: "4rem",
                      backgroundColor: colors.swiftcaseDarkBlue,
                    }}
                  >
                    <Person />
                  </Avatar>
                )}
                <Typography
                  variant="h5"
                  className="dynamic-guide__footer-written-by"
                  color={colors.swiftcaseMidGrey}
                >
                  By {Author?.name ?? "The Swiftcase Team"}
                </Typography>
              </Grid>
              {SimilarGuides.length > 0 && (
                <GetSimilarGuides similarGuideData={SimilarGuides} />
              )}
            </Grid>
          </Container>
        </Box>
      </ScrollSpy>
    </Fragment>
  );
};

export default Guide;
